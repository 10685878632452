import React, {useContext, useEffect, useRef, useState} from 'react';
import {
  eventEmitter,
  lang,
  sessid,
  dynRem,
  analytics,
  axiosConfig, esputnikTracker,
} from '../../common/baseData'
import {Accordion} from 'react-accessible-accordion';
import AdditionalProductGroup from './additionalProductGroup';
import BasketRightBlock from './basketRightBlock';
import CustomScroll from 'react-custom-scroll';
import 'react-custom-scroll/dist/customScroll.css';
import './Basket.css';
import axios from 'axios';
import { ClientWidthContext, BasketDispatchContext, BasketContext } from '../Context/ApplicationContextProvider'
import Lang from '../Lang/Lang';

function Basket(props) {

  //console.info(props.initialState)
  const basketHolderBlockRef = useRef(null);
  // ширина окна
  const clientWidth = useContext(ClientWidthContext);

  const dispatchBasket = useContext(BasketDispatchContext);
  const basketContext = useContext(BasketContext);
  // состояние скролла
  const [modalScrollPosition, setModalScrollPosition] = useState(0);
  // ссылка на родительский контейнер
  const modalRef = useRef(null);

  const [nonRemovableGoods, setNonRemovableGoods] = useState([]);
  const [opened, setOpened] = useState(false);
  // Основное состояние, приходит с сервера при обновлении данных
  const [state, setState] = useState(props.initialState);

  useEffect(() => {
    esputnikTracker.StatusCart(basketContext.products, basketContext.stateHash);
  }, [basketContext.stateHash]);

  /**
   * Установка session storage данных о товарах корзины
   */
  useEffect(() => {
    refreshStorage(state);
  }, [state.basket]);

  /**
   * Инициализация событий
   */
  useEffect(() => {
    // Событие клика по кнопке редактировать на оформлении заказа
    let editButton = document.querySelector(
      '[data-container="basket"][data-use="open"]');
    if (editButton) {
      editButton.addEventListener('click', event => {
        event.preventDefault();
        openBasket();
      });
    }

    // Запрос на обнорвление корзины
    document.body.addEventListener('refreshBasketData', () => {
      //console.info('refreshBasketData event')
      refreshBasketData({}, true).then(() => {
        console.info('Basket refreshed');
      });
    });

    document.body.addEventListener('refreshBasketDataCoupon', (event) => {
      let coupon = event.detail !== null ? event.detail.COUPON : '';
      refreshBasketData({'coupon': coupon}).then(() => {
        console.info('Basket coupon refreshed');
      });
    });

    // обработка события изменения корзины через api
    eventEmitter.on('basketStateChanged', data => {
      refreshBasketData({}, true).then(() => {
        console.info('Basket refreshed');
      });
    });

    // Событие добавления в корзину по старому образцу
    document.addEventListener('click', e => {
      let el = e.target;
      if (el.getAttribute('id') === 'ic-in-basket' || el.getAttribute('id') ===
        'ic-basket') {
        e.preventDefault();
        e.stopPropagation();
      }

      if (el.dataset.hasOwnProperty('container')
        && el.dataset.hasOwnProperty('use') &&
        el.dataset.hasOwnProperty('productId') &&
        el.dataset.container === 'buy' && el.dataset.use === 'order') { // Add to basket event
        e.preventDefault();
        e.stopPropagation();

        let productId = Number(el.dataset.productId);
        let addProductId = null;
        if (el.dataset.hasOwnProperty('addProductId')) {
          addProductId = el.dataset.addProductId;
        }

        if (productId && !inBasket(productId)) {
          eventEmitter.emit('addToCart', {
            productId: productId,
            addProductId: addProductId,
            node: el,
          });
        } else {
          eventEmitter.emit('openSmallCart');
        }
      }
    });

    eventEmitter.on('dropNonRemovableProducts',  data => {
      if (!data.hasOwnProperty('basketIds') ||
          !Array.isArray(data.basketIds)) return;
      //console.info('dropNonRemovableProducts')
      setNonRemovableGoods(prev => prev.filter(id => !data.basketIds.includes(id)));
    });

    // событие установки / снятия блокировки удаления товаров
    eventEmitter.on('setNonRemovableProduct', data => {
      if (!data.hasOwnProperty('basketIds') ||
        !Array.isArray(data.basketIds)) return;
      //console.info('setNonRemovableProduct event', data, nonRemovableGoods)

      setNonRemovableGoods(prev => {
        const updatedSet = new Set([...prev, ...data.basketIds]); // Объединяем старые и новые ID
        return Array.from(updatedSet); // Преобразуем обратно в массив
      });
    });

    eventEmitter.on('addToCart', (data) => {
      //console.info('addToCart')
      if (!data.hasOwnProperty('productId')) return;

      let productId = data['productId'];
      let mainProductId = false;

      // при добавлении гарантии
      if (data.hasOwnProperty('addProductId') && data['addProductId']) {
        productId = [data['productId'], data['addProductId']];
        mainProductId = parseInt(data['productId']);
      } else if (data.hasOwnProperty('mainProductId') &&
        parseInt(data.mainProductId) > 0) {
        // если установлен основной товар
        mainProductId = parseInt(data.mainProductId);
      }

      // признак необходимости открытия окна
      let openWindow = true;
      if (data.hasOwnProperty('openWindow')) openWindow = data['openWindow'];

      addItemToCart(productId, openWindow, mainProductId);
    });

    eventEmitter.on('addToCartService', (data) => {
      console.info('addToCartService');
      if (!data.hasOwnProperty('productId')) return;

      let productId = data['productId'];
      let mainProductId = false;

      // при добавлении гарантии
      if (data.hasOwnProperty('addProductId') && data['addProductId']) {
        productId = [data['productId'], data['addProductId']];
        mainProductId = parseInt(data['productId']);
      } else if (data.hasOwnProperty('mainProductId') &&
        parseInt(data.mainProductId) > 0) {
        // если установлен основной товар
        mainProductId = parseInt(data.mainProductId);
      }

      // признак необходимости открытия окна
      let openWindow = true;
      if (data.hasOwnProperty('openWindow')) openWindow = data['openWindow'];

      addServiceToCart(productId, openWindow, mainProductId);
    });

    eventEmitter.on('addToCartSet', (data) => {
      console.info('addToCartSet', data);
      if (!data.hasOwnProperty('setId')) return;

      let setId = data['setId'];

      // признак необходимости открытия окна
      let openWindow = true;
      if (data.hasOwnProperty('openWindow')) openWindow = data['openWindow'];

      addSetToCart(setId, openWindow);
    });

    eventEmitter.on('removeFromCart', (data) => {
      console.info('removeFromCart');
      if (typeof data !== 'object' || !data.hasOwnProperty('cartId') ||
        !data.hasOwnProperty('productId')) return;
      removeItemFromCart(data);
    });

    eventEmitter.on('changeCartQuantity', (data) => {
      console.info('changeCartQuantity');
      if (typeof data !== 'object' || !data.hasOwnProperty('cartId') ||
        !data.hasOwnProperty('quantity')) return;

      let openWindow = data.hasOwnProperty('openWindow')
        ? data.openWindow
        : true;
      updateItemCartQuantity(data['cartId'], data['quantity'], openWindow);
    });

    eventEmitter.on('openSmallCart', () => {
      console.info('openSmallCart');
      openBasket();
    });

    // сброс данных о товарах в корзине после создания заказа
    eventEmitter.once('savedOrderStage', () => {
      window.localStorage.setItem('basketItemsMap', JSON.stringify({}));
      window.localStorage.setItem('basketItemsData', JSON.stringify([]));
    });

    checkOpenRequest();
    // send inited state
    eventEmitter.emit('basketInited');
  }, []);

  // Проверка корзины
  useEffect(() => {
    if (state.basket.length > 0 || !opened) return;
    closeBasket();
  }, [state.basket]);

  useEffect(() => {
    if (clientWidth < 660) {
      // добавляем обработчик события скролл
      if (modalRef.current) {
        modalRef.current.addEventListener('scroll', handleModalScroll);
      }
      // удаляем обработчик события, при размонтировании компонента
      return () => {
        if (modalRef.current) {
          modalRef.current.removeEventListener('scroll', handleModalScroll);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (opened) {
      document.body.classList.add('modal-open');
      eventEmitter.emit('eSTrackerBasketRefresh', {needCheck: false});
    } else document.body.classList.remove('modal-open');
  }, [opened]);

  // установка текущего значения скролла
  const handleModalScroll = () => {
    if (modalRef.current) {
      const currentPosition = modalRef.current.scrollTop;
      setModalScrollPosition(currentPosition);
    }
  };

  /**
   * Загрузка языковых сообщений
   * @param message
   * @return {string}
   */
  function getMessage(code) {
    return Lang.getMessage(code).
      replace('#MINPRICE_VALUE#',
        state.minPriceForOrder) || '';
  }

  /**
   * Проверяет находится ли товар в корзине
   * @param productId идентификатор товара
   * @return {boolean}
   */
  function inBasket(productId) {
    let inBasket = false;

    state.basket.forEach(record => {
      if (record.hasOwnProperty('PRODUCT_ID') && parseInt(record.PRODUCT_ID) ===
        parseInt(productId))
        inBasket = true;
    });

    return inBasket;
  }

  /**
   * Проверка на наличие ранего запроса на показ корзины
   */
  function checkOpenRequest() {
    if (window.sessionStorage.getItem('basketRequested') === 'true') {
      openBasket();
      window.sessionStorage.setItem('basketRequested', 'false');
    }
  }

  /**
   * Закрытие окна корзины
   */
  function closeBasket() {
    if (opened) setOpened(false);
  }

  function openBasket() {
    let basketItemsData;

    try {
      basketItemsData = JSON.parse(
        window.localStorage.getItem('basketItemsData'));
    } catch (reason) {
      console.error(reason);
    }

    if (!opened &&
      (
        (Array.isArray(basketItemsData) && basketItemsData.length) ||
        (Array.isArray(state.basket) && state.basket.length)
      )
    ) setOpened(true);
  }

  /**
   * Обновление данных корзины пользователя
   * @param data
   * @param init
   */
  function refreshBasketData(data, init, after_refresh = true) {
    return new Promise((resolve) => {
      // Подготовка данных для отправки
      let form_data = new FormData;

      if (typeof data === 'object') {
        for (let i in data) {
          if (!data.hasOwnProperty(i)) continue;
          form_data.append(i, data[i]);
        }
      }

      form_data.append('lang', lang);

      // Начало загрузки
      eventEmitter.emit('showLoader');

      axios.post(state.ajaxPath, form_data, axiosConfig).then(response => {
          eventEmitter.emit('hideLoader');
          if (after_refresh) afterRefreshData(response.data, init);
          resolve(response.data);
        },
      ).catch(error => {
        console.error(error);
        eventEmitter.emit('hideLoader');
        resolve([]);
      });
    });
  }

  function afterRefreshData(response, init) {
    //console.info('afterRefreshData')
    refreshStorage(response);
    // Отправляем события
    if (!init) {
      sendEvents();
      eventEmitter.emit('basketUpdated', response.basket);
    }

    setState(response);
  }

  function refreshStorage(data) {

    try {
      // Просчитываем данные в корзине
      let basketItemsMap = {};
      let basketItemsData = [];

      data.basket.map(row => {
        // TODO изменить ключи и значения для использования нескольких одиннаковых товаров в корзине
        basketItemsMap[row.PRODUCT_ID] = row.ID;
        basketItemsData.push({
          cartId: row.ID,
          productId: row.PRODUCT_ID,
          quantity: row.QUANTITY,
          price: row.BASKET_PRICE.PRICE,
          currency: row.BASKET_PRICE.CURRENCY,
        });
      });

      // Сохраняем в storage
      window.localStorage.setItem('basketItemsMap', JSON.stringify(basketItemsMap));
      window.localStorage.setItem('basketItemsData', JSON.stringify(basketItemsData));

      dispatchBasket && dispatchBasket({
        type: 'update',
        payload: {products: basketItemsData, stateHash: data.stateHash},
      });
    } catch (err) {
      console.error('refreshStorage', err);
    }
  }

  /**
   * Отправка событий изменения твоаров корзины
   */
  function sendEvents() {
    eventEmitter.emit('eSTrackerBasketRefresh', {needCheck: true});
    // Отправляем событие обновления корзины
    document.body.dispatchEvent(new CustomEvent('basketUpdated'));
  }

  function addCoupon(coupon) {
    refreshBasketData({'coupon': coupon}).then(() => console.log('addCoupon'));
  }

  function removeCoupon(coupon) {
    refreshBasketData({'deleteCoupon': coupon.COUPON}).
      then(() => console.log('removeCoupon'));
  }

  function removeItemFromCart(data) {
    // Отпарвка запроса на удаление
    refreshBasketData({sbblRemoveItemFromCart: data['cartId']}).
      then(response => {
        // Уведомление об удалении товара из корзины
        eventEmitter.emit('removedCartItem', {
          productId: data['productId'],
          basketItemsMap: JSON.parse(
            window.localStorage.getItem('basketItemsMap')),
        });
        // Отправка события ecommerce
        triggerRemoveEvent(data);
      });
  }

  function addItemToCart(id, openWindow = true, mainProductId = false) {
    // Отправка запроса на добавление твоара в корзину
    let data = {sbblAddItemToCart: id};

    // пробрасываем основной товар
    if (mainProductId > 0)
      data.mainProductId = mainProductId;

    refreshBasketData(data).then(() => {
      // Уведомление о добавлении товара в корзину
      eventEmitter.emit('addedToCart', {
        productId: id,
        basketItemsMap: JSON.parse(
          window.localStorage.getItem('basketItemsMap')),
      });
      // Отправка события ecommerce
      triggerTrackerEvents(id);
      // Показ корзины
      if (openWindow) openBasket();
    });
  }

  function addServiceToCart(id, openWindow = true, mainProductId = false) {
    // Отправка запроса на добавление твоара в корзину
    let data = {sbblAddServiceToCart: id};

    // пробрасываем основной товар
    if (mainProductId > 0)
      data.mainProductId = mainProductId;

    refreshBasketData(data).then(() => {
      // Уведомление о добавлении товара в корзину
      eventEmitter.emit('addedToCart', {
        productId: id,
        basketItemsMap: JSON.parse(
          window.localStorage.getItem('basketItemsMap')),
      });
      // Отправка события ecommerce
      triggerTrackerEvents(id);
      // Показ корзины
      if (openWindow) openBasket();
    });
  }

  function addSetToCart(id, openWindow = true) {
    // Отправка запроса на добавление твоара в корзину
    let data = {sbblAddSetToCart: id};

    refreshBasketData(data).then(() => {
      // Уведомление о добавлении товара в корзину
      eventEmitter.emit('addedToCart', {
        productId: id,
        basketItemsMap: JSON.parse(
          window.localStorage.getItem('basketItemsMap')),
      });
      // Отправка события ecommerce
      triggerTrackerEvents(id);
      // Показ корзины
      if (openWindow) openBasket();
    });
  }

  function updateItemCartQuantity(id, quantity, openWindow = true, originProductId = 0, operation = 'add') {
    // Отправка запроса на обновление
    refreshBasketData({sbblUpdateItemQuantity: id, sbblQuantity: quantity}).
      then(() => {
        // Уведомление об обновлении записи в корзине
        eventEmitter.emit('updatedCartItem', {
          productId: id,
          basketItemsMap: JSON.parse(
            window.localStorage.getItem('basketItemsMap')),
        });
        // Показ корзины
        if (openWindow) openBasket();

        if(originProductId !== 0){
          if(operation === 'add'){
            // Отправка события ecommerce
            triggerTrackerEvents(originProductId, quantity, operation);
          } else if(operation === 'remove'){
            triggerTrackerEvents(originProductId, quantity, operation);
          }
        }
      });
  }

  /**
   * Trigger tracking events like Google Analytics
   * @param data - removed product data
   */
  function triggerRemoveEvent(data) {
    try {
      loadProductData(data['productId']).then(response => {
        if (response.success) {

          dataLayer.push({
            'ecommerce': {
              'currencyCode': 'UAH',
              'remove': {
                'products': [
                  {
                    'name': response.data.name,
                    'id': response.data.sku,
                    'price': response.data.price.toString(),
                    'brand': response.data.brand,
                    'category': response.data.categoryPath,
                    'variant': response.data.color,
                    'quantity': Number(data['quantity']),
                  }],
              },
            },
            'event': 'gtm-ee-event',
            'gtm-ee-event-category': 'Enhanced Ecommerce',
            'gtm-ee-event-action': 'Removing a Product from a Shopping Cart',
            'gtm-ee-event-non-interaction': 'False',
          });

          if (response.data.ga4) {
            analytics.remove_from_cart(response.data.ga4)
          }

        } else console.error(response.error);
      });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Trigger tracking events like facebook pixel
   * @param id Product identifier
   * @param quantity Product identifier
   * @param operation Operation type
   */
  function triggerTrackerEvents(id, quantity = 1, operation = 'add') {
    try {
      loadProductData(id).then(function(response) {
        try {
          //console.info(response);
          eventEmitter.emit('MSAddToCart', {productId: response.data.sku});

          if (response.success && window.fbq instanceof Object) {

            if (operation === 'add') {
              // facebook trigger event
              fbq('track', 'AddToCart', {
                content_name: response.data.name,
                content_category: response.data.category,
                value: response.data.priceUAH,
                currency: 'UAH',
                content_ids: [response.data.id],
                content_type: 'product',
              });

              if (response.data.ga4) {
                analytics.add_to_cart(response.data.ga4)
              }

              dynRem.pushToDataLayer({
                'eventName': 'add_to_cart',
                'items': response.data.id,
                'price': response.data.priceUAH,
              });
            } else {
              if (response.data.ga4) {
                analytics.remove_from_cart(response.data.ga4)
              }
            }


          } else console.error(response.error);
        } catch (error) {
          console.error(error);
        }
      }, function(error) {
        console.error(error);
      });
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Функция возвращает разделы товаров
   * @param categoryPath
   * @returns {{}}
   */
  function createCategoryFields(categoryPath) {
    const categories = categoryPath ?
        categoryPath.split('%').map(cat => cat.trim()) :
        [];
    const categoryFields = {};
    categories.forEach((category, index) => {
      categoryFields[`item_category${index === 0 ?
          '' :
          index + 1}`] = category;
    });
    return categoryFields;
  }

  /**
   * Отправка события перед переходом на страницу оформления заказа
   */
  function triggerTransitionCheckout() {
    if (!window.fbq) return;
    let data = {
      content_ids: [],
      content_type: 'product',
      value: 0,
      currency: 'UAH',
      num_items: 0,
    };

    let items = [].slice.apply(basketHolderBlockRef.current.querySelectorAll(
      '[data-container="offerDetailInfo"]'));

    // Подготовка данных для отправки
    let form_data = new FormData;
    if (typeof data === 'object') {
      for (let i in data) {
        if (!data.hasOwnProperty(i)) continue;
        form_data.append(i, data[i]);
      }
    }
    form_data.append('lang', lang);
    // Начало загрузки
    eventEmitter.emit('showLoader');
    axios.post(state.ajaxPath, form_data, axiosConfig).then(response => {
      eventEmitter.emit('hideLoader');
      if (response.data.ga4) {
        analytics.begin_checkout(response.data.ga4)
      }
    },
    ).catch(error => {
      console.error(error);
      eventEmitter.emit('hideLoader');
    });

    if (items.length > 0) {
      items.forEach(item => {
        if (!(item instanceof HTMLElement) ||
          !item.hasAttribute('data-price')) return;
        data.value += Number(item.getAttribute('data-price'));
        data.content_ids.push(item.getAttribute('data-productId'));
        data.num_items++;
      });
    }

    fbq('track', 'InitiateCheckout', data);
  }

  /**
   *
   * @param id
   * @returns {Promise<*>}
   */
  function loadProductData(id) {
    return refreshBasketData({sbblGetProductData: id}, false, false);
  }

  /**
   * Возвращает открытые блоки дополнительных товаров
   */
  function preExpandedUuid() {
    let keys = [];
    state.additional.forEach((group, key) => {
      if (group.opened) keys.push(key);
    });
    return keys;
  }

  return (
    <>
      <div className={`modal-backdrop ${opened ? 'in' : ''}`}
           style={{display: (!opened) ? 'none' : ''}}/>
      <div className={`modal fade ${opened ? 'in' : ''}`} id="modal-basket"
           role="dialog"
           tabIndex="-1"
           onClick={e => {
             if (e.target.id === 'modal-basket') closeBasket();
           }}>
        <div className="modal-dialog" ref={modalRef}>
          <div
            className={`modal-content modal-content--basket ${state.additional.length
              ? 'basket-service'
              : 'without-basket-left'}`}
            id="bx-small-cart-content"
            data-num={state.total.NUM_PRODUCTS}>
            <a href="#" className="close-modal" data-dismiss="modal"
               onClick={e => {
                 e.preventDefault();
                 e.stopPropagation();
                 closeBasket();
               }}/>
            <div
              className={`modal-body modal-body--basket ${state.additional.length
                ? 'modal-body--basket-service'
                : 'bg-white'}`}>
              {
                opened ?
                  state.additional.length ?
                    <div className="basket-conteiner"
                         ref={basketHolderBlockRef}>
                      <div className="basket-left">
                        <CustomScroll heightRelativeToParent="100%"
                                      allowOuterScroll={true}>
                          <div className="accordeon_items">
                            <div
                              className="accordeon_item basket-accordeon_main accordeon_item-accordeon">
                              <div className="accordeon_content">
                                <div className="basket-accordeon">
                                  {
                                    Array.isArray(state.additional) &&
                                    state.additional.length ?
                                      <Accordion
                                        allowZeroExpanded={true}
                                        allowMultipleExpanded={true}
                                        preExpanded={preExpandedUuid()}
                                        className={'accordeon_items'}>
                                        {
                                          state.additional.map(
                                            (group, key) => {
                                              return <AdditionalProductGroup
                                                buyButtonColor={state.buyButtonColor}
                                                getMessage={getMessage}
                                                addToBasket={addItemToCart}
                                                group={group}
                                                key={key}
                                                uuid={key}/>;
                                            })
                                        }
                                      </Accordion> :
                                      <></>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </CustomScroll>
                      </div>
                      <div className="basket-right">
                        <BasketRightBlock getMessage={getMessage}
                                          closeBasket={closeBasket}
                                          page={props.page}
                                          nonRemovableGoods={nonRemovableGoods}
                                          eventEmitter={eventEmitter}
                                          showCreditButton={state.showCreditButton}
                                          basket={state.basket}
                                          addCoupon={addCoupon}
                                          removeCoupon={removeCoupon}
                                          removeFromBasket={removeItemFromCart}
                                          updateBasketQuantity={updateItemCartQuantity}
                                          basketHolderBlockRef={basketHolderBlockRef}
                                          minPriceForOrder={state.minPriceForOrder}
                                          minPriceForCredit={state.minPriceForCredit}
                                          couponList={state.couponList}
                                          coupon={state.coupon}
                                          couponDiscountPrice={state.couponDiscountPrice}
                                          refreshBasketData={refreshBasketData}
                                          total={state.total}
                                          orderPage={state.orderPage}
                                          modalScrollPosition={modalScrollPosition}
                                          triggerTransitionCheckout={triggerTransitionCheckout}/>
                      </div>
                    </div> :
                    state.basket.length
                      ?
                      <BasketRightBlock getMessage={getMessage}
                                        closeBasket={closeBasket}
                                        page={props.page}
                                        nonRemovableGoods={nonRemovableGoods}
                                        eventEmitter={eventEmitter}
                                        showCreditButton={state.showCreditButton}
                                        basket={state.basket}
                                        addCoupon={addCoupon}
                                        removeCoupon={removeCoupon}
                                        removeFromBasket={removeItemFromCart}
                                        updateBasketQuantity={updateItemCartQuantity}
                                        basketHolderBlockRef={basketHolderBlockRef}
                                        minPriceForOrder={state.minPriceForOrder}
                                        minPriceForCredit={state.minPriceForCredit}
                                        couponList={state.couponList}
                                        coupon={state.coupon}
                                        couponDiscountPrice={state.couponDiscountPrice}
                                        refreshBasketData={refreshBasketData}
                                        total={state.total}
                                        orderPage={state.orderPage}
                                        modalScrollPosition={modalScrollPosition}
                                        triggerTransitionCheckout={triggerTransitionCheckout}/>
                      :
                      <></> :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Basket;