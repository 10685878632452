import React, {useEffect, useState} from 'react';
import BasketProductPriceBlock from '../basketProductPriceBlock';
import {decode} from 'html-entities';
import InfoIcon from '../images/info.svg';

function BasketProduct(props) {

  const [canRemove, setCanRemove] = useState(true);
  const product = props.product;

  // установка признака вохможности обновления
  useEffect(() => {
    setCanRemove(props.canRemove);
  }, [props.canRemove]);

  function showPreview(event) {
    event.preventDefault();
    event.stopPropagation();
    // формируем и показываем окно с описанием
    import(/* webpackPreload: true */ '../../../popup').then(module => {
      let popup = new module.Popup('additional-basket-products', false, false,
          true);
      // Устанавливаем тело окна
      popup.body =
          `<div class="additional-product-popup-title">${product.NAME}</div>` +
          `<div class="additional-product-popup-body">${product.PREVIEW_TEXT}</div>`;
      popup.open();
    });
  }

  return (
      <div className="order-detail-item">
        <div className="order-detail-img">
          {
            !product.WARRANTY_DATA.WARRANTY_TYPE ?
                <a href={product.URL} className="order-detail-img-link">
                  <img src={product.IMAGE_150_SRC} alt={decode(product.NAME)}
                       loading={'lazy'}/>
                </a> :
                <div className="order-detail-img-link">
                  <img src={product.IMAGE_150_SRC}
                       className={'order-detail-img-link-warranty'}
                       onClick={showPreview} alt={decode(product.NAME)}
                       loading={'lazy'}/>
                </div>
          }
        </div>
        <div className="order-detail-info"
             data-product-id={product.PRODUCT_ID}
             data-price={product.DISPLAY_PRICE.PRICE}
             data-cart-product={btoa(JSON.stringify(
                 {
                   productId: product.ID,
                   price: product.DISPLAY_PRICE.PRICE,
                   quantity: product.QUANTITY,
                   currency: product.BASKET_PRICE.CURRENCY,
                 },
             ))}
             data-container="offerDetailInfo">
          <span className="order-detail-linkname-underline">
          {
            !product.WARRANTY_DATA.WARRANTY_TYPE ?
                <a href={product.URL} className="order-detail-linkname">{decode(
                    product.NAME)}</a> :
                <span className="order-detail-linkname warranty-item"
                      onClick={showPreview}>
                <span>{decode(product.NAME)}</span>
                <img src={InfoIcon}
                     width={`14px`}
                     height={`14px`}
                     alt={`info`}
                     title={`info`}
                />
              </span>
          }
          </span>
          {
            product.ARTICLE ?
              <div className="code break-all"><span>{product.ARTICLE}</span></div> :
              <></>
          }
          <div className="order-detail-row">
            <div className="col-quantity">
              {
                !product.GIFT ?
                    <div className="basket_quantity_control">
                      <div className="quantity_control"
                           onClick={e => {
                             e.preventDefault();
                             e.stopPropagation();
                             props.updateBasketQuantity(product.ID,
                                 product.QUANTITY - 1, true, product.PRODUCT_ID, 'remove');
                           }}>
                        <a href="#" className="minus"/>
                      </div>
                      <span className="item_quantity">{product.QUANTITY}</span>
                      <div className="quantity_control"
                           onClick={e => {
                             e.preventDefault();
                             e.stopPropagation();
                             props.updateBasketQuantity(product.ID,
                                 product.QUANTITY + 1, true, product.PRODUCT_ID, 'add');
                           }}>
                        <a href="#" className="plus"/>
                      </div>
                    </div> :
                    <></>
              }
            </div>
            {
              props.clietWidth < 666 ?
                  <div className={'price-action'}>
                    <BasketProductPriceBlock product={product}
                                             getMessage={props.getMessage}/>
                  </div> :
                  <></>
            }
          </div>
        </div>
        {
          props.clietWidth >= 666 ?
              <div className={'price-action'}>
                <BasketProductPriceBlock product={product}
                                         getMessage={props.getMessage}/>
                {
                    canRemove && (<i className="ic-close-sm remove"
                                     onClick={e => {
                                       e.preventDefault();
                                       e.stopPropagation();
                                       props.removeFromBasket(
                                           {
                                             cartId: product.ID,
                                             productId: product.PRODUCT_ID,
                                             quantity: product.QUANTITY - 1
                                           });
                                     }}/>)

                }
              </div> :
              <>
                {
                    canRemove &&
                    (<i className="ic-close-sm remove"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.removeFromBasket(
                              {
                                cartId: product.ID,
                                productId: product.PRODUCT_ID,
                                quantity: product.QUANTITY - 1
                              });
                        }}/>)
                }
              </>
        }
      </div>
  );
}

export default BasketProduct;